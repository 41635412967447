import { render, staticRenderFns } from "./SportsBetInfo.vue?vue&type=template&id=80ef9274&scoped=true&"
import script from "./SportsBetInfo.vue?vue&type=script&lang=js&"
export * from "./SportsBetInfo.vue?vue&type=script&lang=js&"
import style0 from "./SportsBetInfo.vue?vue&type=style&index=0&id=80ef9274&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ef9274",
  null
  
)

export default component.exports